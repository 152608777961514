module.exports = {
	'app.commonjs.placeholder.select_time': '选择时间',
	'app.commonjs.placeholder.select_date': '选择日期',
	'app.commonjs.placeholder.nodata': '暂无数据',
	'app.commonjs.message.error_format': '附件格式不正确!',
	'app.commonjs.message.over_size': '附件大小不超过3MB!',
	'app.commonjs.message.size': '附件大小不超过',
	'app.commonjs.message.unit': 'MB!',
	'app.commonjs.message.uploaded': '上传成功',
	'app.commonjs.message.upload_failed': '上传失败',
	'app.commonjs.message.delete_first': '需要先删除:',
	'app.commonjs.message.deleted': '删除成功',
	'app.commonjs.message.selectdata': '请选择要导出的数据',
	'app.carryout.status.created': '创建',
	'app.carryout.status.to_assigned': '待分配',
	'app.carryout.status.wait_approve': '待审批',
	'app.carryout.status.approved': '已通过',
	'app.carryout.status.not_approved': '未通过',
	'app.carryout.status.withdraw': '撤回',
	'app.carryout.status.return_back': '退回',
	'app.carryout.status.bewithdraw': '被撤回',
	'app.carryout.status.bereturn': '被退回',
	'app.carryout.status.closed': '已关闭',
	'app.carryout.status.exception_closed': '异常关闭',
	'app.carryout.status.not_active': '未生效',
	'app.carryout.status.submit': '提交',
	'app.project.stage.single': '单行文本',
	'app.project.stage.number': '数字',
	'app.project.stage.textarea': '多行文本',
	'app.project.stage.single_select': '下拉单选',
	'app.project.stage.multi_select': '下拉多选',
	'app.project.stage.radio': '单选按钮',
	'app.project.stage.multi_radio': '多选按钮',
	'page.oneStop.cardOperation.oneTime': '一次性',
	'page.oneStop.cardOperation.long': '长期',
	'page.oneStop.cardOperation.photo1': '内部人员',
	'page.oneStop.cardOperation.photo2': '外部人员',
	'page.oneStop.cardAnnual.recordOption1': '照片',
	'page.oneStop.cardAnnual.recordOption2': '视频',
	'page.oneStop.cardAnnual.recordOption3': '声音',
	'page.oneStop.cardAnnual.recordOption4': '其他',
	'page.oneStop.cardOperation.photo3': '开发',
	'page.oneStop.cardOperation.photo4': '生产',
	'page.oneStop.cardOperation.photo5': '行政',
	'page.oneStop.cardOperation.photo6': '其他',
	'app.page.content.none': '-',
	'page.oneStop.cardOperation.photo7': '内部',
	'page.oneStop.cardOperation.photo68': '外部',
	'page.oneStop.cardOperation.gName': '设备名称',
	'page.oneStop.cardOperation.serialNumber': '序列号',
	'page.oneStop.cardOperation.fixedAsset': '固定资产号',
	'page.oneStop.cardOperation.modelNumber': '电脑型号',
	'app.page.text.whiteCard': '白卡',
	'app.page.text.chipCard': '芯片卡',
	'app.page.text.yes': '是',
	'app.page.text.no': '否',
	'page.construction.monitor.contractor2': '供应商',
	'page.construction.monitor.contractor': '承包商',
	'app.component.side_layout.enter_opinion': '请输入意见',
	'app.component.side_layout.submitted': '提交成功',
	'app.component.side_layout.logout_success': '注销成功',
	'page.event.accessrecord.month': '月',
	'page.event.accessrecord.day': '天',
	'page.event.accessrecord.hour': '时',
	'page.event.accessrecord.minute': '分',
	'page.event.accessrecord.second': '秒',
	'page.oneStop.cardOperation.persNoId': '员工号',
	'page.oneStop.cardOperation.originalCard': '原卡号',
	'app.component.exception.403': '抱歉，你无权访问该页面',
	'app.component.exception.404': '抱歉，你访问的页面不存在',
	'app.component.exception.500': '抱歉，服务器出错了',
	'app.component.exception.notice': '你需要修改密码，才可以继续访问。',
	'app.component.exception.attention': '注意',
	'app.component.exception.error': '抱歉，发生错误了。',
	'page.construction.projectAudit.staffInfo1': '姓名',
	'page.keys.annual.costCenter': '成本中心',
	'page.keys.annual.department': '部门',
	'page.oneStop.cardOperation.cardNumber': '卡号',
	'page.doc.template.form1': '单行文本',
	'page.doc.template.form2': '数字',
	'page.doc.template.form3': '多行文本',
	'page.doc.template.form4': '下拉单选',
	'page.doc.template.form5': '下拉多选'
};
