import React from 'react';
import { FormattedMessage } from 'react-intl';
import intl from 'react-intl-universal';

const menuList = [
	{
		title: 'system',
		name: <FormattedMessage id='menu.system' defaultMessage='系统管理' />,
		to: '/system',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.system.type.title'
						defaultMessage='配置组织（单位）类型、权限、职务'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.type'
						defaultMessage='组织类型配置'
					/>
				),
				to: '/system/org/type',
				children: [],
				is_show: false,
				order: 1,
				wx_url: null,
				is_super: true
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.role.title'
						defaultMessage='配置各组织（单位）角色与相应权限'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.role'
						defaultMessage='角色权限'
					/>
				),
				to: '/system/role',
				children: [],
				is_show: false,
				order: 2,
				wx_url: null,
				is_super: true
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.gate.title'
						defaultMessage='配置闸机'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.gate'
						defaultMessage='闸机管理'
					/>
				),
				to: '/system/turnstile',
				children: [],
				is_show: false,
				order: 3,
				wx_url: null,
				is_super: true
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.setting.title'
						defaultMessage='项目新组织（单位）的创建，查看'
					/>
				),
				name: (
					<FormattedMessage id='menu.system.register' defaultMessage='注册配置' />
				),
				to: '/system/settings',
				children: [],
				is_show: false,
				order: 4,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.org apply.title'
						defaultMessage='配置组织注册审批人'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.org-apply'
						defaultMessage='组织创建'
					/>
				),
				to: '/system/org',
				children: [],
				is_show: false,
				order: 5,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.org audit.title'
						defaultMessage='组织（单位）申请进行审批，“项目配置“需有该人员才有权限'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.org-audit'
						defaultMessage='组织审批'
					/>
				),
				to: '/system/org/application',
				children: [],
				is_show: false,
				order: 6,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.user.title'
						defaultMessage='浏览各自组织里的用户，对某个用户禁用，移出该项目'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.user'
						defaultMessage='账号管理'
					/>
				),
				to: '/system/user',
				children: [],
				is_show: false,
				order: 7,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.account creation.title'
						defaultMessage='创建组织单位里的新用户，得到此码'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.account creation'
						defaultMessage='账号创建'
					/>
				),
				to: '/system/staff/org',
				children: [],
				is_show: true,
				order: 8,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.account approval.title'
						defaultMessage='批准人员加入该组织（单位）'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.account approval'
						defaultMessage='账号审批'
					/>
				),
				to: '/system/staff/approve',
				children: [],
				is_show: true,
				order: 9,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.system.operation log.title'
						defaultMessage='操作日志'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.system.operation log'
						defaultMessage='操作日志'
					/>
				),
				to: '/system/log',
				children: [],
				is_show: true,
				order: 10,
				wx_url: null
			}
		],
		is_show: false,
		order: 1,
		wx_url: null
	},
	{
		title: 'jinrixianchang',
		name: <FormattedMessage id='menu.today' defaultMessage='今日现场' />,
		to: '/today',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.today.message.title'
						defaultMessage='创建系统信息（语音或文字）推送，系统所有用户将看到'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.today.message'
						defaultMessage='消息通知'
					/>
				),
				to: '/today/message',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.today.propaganda.title'
						defaultMessage='配置大屏幕轮播图和说明'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.today.propaganda'
						defaultMessage='轮播图'
					/>
				),
				to: '/today/propaganda',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: (
					<FormattedMessage
						id='menu.today.barometer.title'
						defaultMessage='查看气象信息'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.today.barometer'
						defaultMessage='晴雨表'
					/>
				),
				to: '/today/barometer',
				children: [],
				is_show: true,
				order: 3
			},
			{
				title: (
					<FormattedMessage
						id='Regional details'
						defaultMessage='区域配置及全景图详情'
					/>
				),
				name: (
					<FormattedMessage
						id='Area management configuration'
						defaultMessage='全景图配置'
					/>
				),
				to: '/today/region1',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='Regional details'
						defaultMessage='区域配置及全景图详情'
					/>
				),
				name: (
					<FormattedMessage
						id='Area management configuration'
						defaultMessage='全景图配置'
					/>
				),
				to: '/today/region',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='Area details'
						defaultMessage='区域查看及全景图详情'
					/>
				),
				name: (
					<FormattedMessage
						id='Area management view'
						defaultMessage='全景图查看'
					/>
				),
				to: '/today/browse1',
				children: [],
				is_show: true,
				order: 5
			},
			{
				title: (
					<FormattedMessage
						id='Area details'
						defaultMessage='区域查看及全景图详情'
					/>
				),
				name: (
					<FormattedMessage
						id='Area management view'
						defaultMessage='全景图查看'
					/>
				),
				to: '/today/browse',
				children: [],
				is_show: true,
				order: 5
			}
		],
		is_show: true,
		order: 2,
		wx_url: null
	},
	{
		title: 'anfangguanli',
		name: <FormattedMessage id='menu.safety' defaultMessage='安防管理' />,
		to: '/safety',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.safety.accesscard.title'
						defaultMessage='浏览所有授权，对人员权限进行配置'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.accesscard'
						defaultMessage='门禁管理'
					/>
				),
				to: '/safety/accesscard',
				children: [],
				is_show: true,
				order: 3,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.safety.accesscard-enabled.title'
						defaultMessage='对已获绿码人员进行特殊情况下的临时手工出入登记'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.accesscard-enabled'
						defaultMessage='临时出入'
					/>
				),
				to: '/safety/enabled/accesscard',
				children: [],
				is_show: true,
				order: 4,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.safety.entryrecord.title'
						defaultMessage='本组织（单位）的人员进出记录'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.entryrecord'
						defaultMessage='进出记录'
					/>
				),
				to: '/safety/entryrecord',
				children: [],
				is_show: true,
				order: 5,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.safety.entry-apply.title'
						defaultMessage='为组织（单位）成员申请入场所需的门禁授权绿码'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.entry-apply'
						defaultMessage='门禁申请'
					/>
				),
				to: '/safety/list/factoryapply',
				children: [],
				is_show: true,
				order: 6,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.safety.entry-audit.title'
						defaultMessage='审批门禁申请'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.entry-audit'
						defaultMessage='门禁审批'
					/>
				),
				to: '/safety/my/factoryapply',
				children: [],
				is_show: true,
				order: 7,
				wx_url: null
			},
			{
				title: (
					<FormattedMessage
						id='menu.safety.admission.title'
						defaultMessage='配置可以审批绿码的人'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.safety.admission'
						defaultMessage='入场配置'
					/>
				),
				to: '/safety/config',
				children: [],
				is_show: true,
				order: 8,
				wx_url: null
			}
			// { "title": "", "name": '组织进出记录', "to": "/safety/org/entryrecord", "children": [], "is_show": true, "order": 6, "wx_url": null },
		],
		is_show: true,
		order: 4,
		wx_url: null
	},
	{
		title: 'peixun',
		name: <FormattedMessage id='menu.training' defaultMessage='培训管理' />,
		to: '/training',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.training.paper.title'
						defaultMessage='试卷的配置'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.training.paper'
						defaultMessage='试题库'
					/>
				),
				to: '/training/paper',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.training.material.title'
						defaultMessage='培训资料的配置'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.training.material'
						defaultMessage='资料库'
					/>
				),
				to: '/training/material',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: (
					<FormattedMessage
						id='menu.training.managemen.title'
						defaultMessage='配置培训内容，绑定培训所需的资料、试卷及相关通过标准'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.training.managemen'
						defaultMessage='培训配置'
					/>
				),
				to: '/training/management',
				children: [],
				is_show: true,
				order: 3
			},
			{
				title: (
					<FormattedMessage
						id='menu.training.start.title'
						defaultMessage='启动培训，并配置需要参加培训的时限、人员与门禁联动'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.training.start'
						defaultMessage='培训启动'
					/>
				),
				to: '/training/start/training',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='menu.training.record.title'
						defaultMessage='浏览自己或组织（单位）内所有员工的培训记录'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.training.record'
						defaultMessage='培训记录'
					/>
				),
				to: '/training/myrecord',
				children: [],
				is_show: true,
				order: 5
			}
			// { "title": "", "name": <FormattedMessage id="menu.training.record" defaultMessage="所有培训记录" />, "to": "/training/record", "children": [], "is_show": true, "order": 6, },
			// { "title": "", "name": "组织培训记录", "to": "/training/org/record", "children": [], "is_show": true, "order": 7, },
		],
		is_show: true,
		order: 5,
		wx_url: null
	},
	{
		title: 'wendang',
		name: <FormattedMessage id='menu.document' defaultMessage='文档管理' />,
		to: '/document',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.document.directory.title'
						defaultMessage='配置文档根目录及相关权限'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.directory'
						defaultMessage='注册文档目录'
					/>
				),
				to: '/document/directory',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.register.document.title'
						defaultMessage='注册文档区域的操作，包括查看、上传、下载、分享、替换、修改、禁用等'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.register.document'
						defaultMessage='注册文档管理'
					/>
				),
				to: '/document/register/document',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.all.document.title'
						defaultMessage='浏览所有文档'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.all.document'
						defaultMessage='所有注册文档'
					/>
				),
				to: '/document/all',
				children: [],
				is_show: true,
				order: 3
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.org.document.title'
						defaultMessage='浏览组织相关文档'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.org.document'
						defaultMessage='组织内部文档'
					/>
				),
				to: '/document/org',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.temporary.document.title'
						defaultMessage='临时文档区的操作，分享、上传、下载、修改、禁用等'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.temporary.document'
						defaultMessage='临时文档管理'
					/>
				),
				to: '/document/temporary/document',
				children: [],
				is_show: true,
				order: 5
			}
			// { "title": "浏览工作流审批所产生的相关文档", "name": <FormattedMessage id="menu.document.workflow.document" defaultMessage="工作流文档" />, "to": "/document/workflow", "children": [], "is_show": true, "order": 6 },
		],
		is_show: true,
		order: 6
	},
	// 竣工文档
	{
		title: 'jungongwendang',
		name: <FormattedMessage id='menu.As-built documents' defaultMessage='竣工文档' />,
		to: '/complete',
		children: [
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.As-built.type'
						defaultMessage='类型配置'
					/>
				),
				to: '/complete/menu',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.As-built.management'
						defaultMessage='竣工文档管理'
					/>
				),
				to: '/complete/document',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.As-built.all'
						defaultMessage='所有标签'
					/>
				),
				to: '/complete/alldocument',
				children: [],
				is_show: false,
				order: 3
			},
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.As-built.cprule'
						defaultMessage='升级规则'
					/>
				),
				to: '/complete/escalation',
				children: [],
				is_show: false,
				order: 4
			}
		],
		is_show: true,
		order: 7,
		wx_url: null
	},
	{
		title: 'fund',
		name: '重要问题',
		to: '/task',
		children: [
			{
				title: '',
				name: '问题类型',
				to: '/task/type',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: '',
				name: '关闭理由',
				to: '/task/close-reason',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: '',
				name: '提醒规则',
				to: '/task/remind-rule',
				children: [],
				is_show: true,
				order: 3
			},
			{
				title: '',
				name: '问题发起',
				to: '/task/task-start',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: '',
				name: '问题处理',
				to: '/task/task-resolve',
				children: [],
				is_show: true,
				order: 5
			}
		],
		is_show: true,
		order: 6
	},
	{
		title: 'check-square',
		name: '巡更管理',
		to: '/inspection',
		children: [
			{
				title: '',
				name: '巡更点位',
				to: '/inspection/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '巡更路线',
				to: '/inspection/2',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: '巡更分组',
				to: '/inspection/3',
				children: [],
				is_show: false,
				order: 3
			},
			{
				title: '',
				name: '巡更管理',
				to: '/inspection/4',
				children: [],
				is_show: false,
				order: 4
			},
			{
				title: '',
				name: '我的巡更',
				to: '/inspection/5',
				children: [],
				is_show: false,
				order: 5
			},
			{
				title: '',
				name: '巡更记录',
				to: '/inspection/6',
				children: [],
				is_show: false,
				order: 6
			}
		],
		is_show: false,
		order: 6
	},
	{
		title: 'bug',
		name: '缺陷管理',
		to: '/defects',
		children: [
			{
				title: '',
				name: '缺陷类型管理',
				to: '/defects/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '我的缺陷工单',
				to: '/defects/2',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: '待处理缺陷',
				to: '/defects/3',
				children: [],
				is_show: false,
				order: 3
			},
			{
				title: '',
				name: '所有缺陷',
				to: '/defects/4',
				children: [],
				is_show: false,
				order: 4
			}
		],
		is_show: false,
		order: 7
	},
	{
		title: 'code-sandbox',
		name: 'TPM管理',
		to: '/eqp',
		children: [
			{
				title: '',
				name: '维修卡类型',
				to: '/eqp/cardtype',
				children: [],
				is_show: false,
				order: 16,
				wx_url: null
			},
			{
				title: '',
				name: '供应商',
				to: '/eqp/supplier',
				children: [],
				is_show: false,
				order: 15,
				wx_url: null
			},
			{
				title: '',
				name: '所有维修卡',
				to: '/eqp/maintcard',
				children: [],
				is_show: false,
				order: 14,
				wx_url: null
			},
			{
				title: '',
				name: '我的维修卡',
				to: '/eqp/mymaintcard',
				children: [],
				is_show: false,
				order: 13,
				wx_url: null
			},
			{
				title: '',
				name: '常用报表',
				to: '/eqp/dashboard',
				children: [],
				is_show: false,
				order: 12,
				wx_url: null
			},
			{
				title: '',
				name: '我的维护记录',
				to: '/eqp/mychecklist',
				children: [],
				is_show: false,
				order: 8,
				wx_url: null
			},
			{
				title: '',
				name: '所有维护记录',
				to: '/eqp/checklist',
				children: [],
				is_show: false,
				order: 9,
				wx_url: null
			},
			{
				title: '',
				name: '维护类型',
				to: '/eqp/mtype',
				children: [],
				is_show: false,
				order: 7,
				wx_url: null
			},
			{
				title: '',
				name: '所有任务单',
				to: '/eqp/task',
				children: [],
				is_show: false,
				order: 6,
				wx_url: null
			},
			{
				title: '',
				name: '我的任务单',
				to: '/eqp/mytask',
				children: [],
				is_show: false,
				order: 5,
				wx_url: null
			},
			{
				title: '',
				name: '任务包管理',
				to: '/eqp/package',
				children: [],
				is_show: false,
				order: 4,
				wx_url: null
			},
			{
				title: '',
				name: '规则管理',
				to: '/eqp/rule',
				children: [],
				is_show: false,
				order: 3,
				wx_url: null
			},
			{
				title: '',
				name: '系统设备',
				to: '/eqp/syseqp',
				children: [],
				is_show: false,
				order: 2,
				wx_url: null
			},
			{
				title: '',
				name: 'Key维护',
				to: '/eqp/key',
				children: [],
				is_show: false,
				order: 1,
				wx_url: null
			}
		],
		is_show: false,
		order: 8,
		wx_url: null
	},
	{
		title: 'shopping-cart',
		name: '材料进场(二期)',
		to: '/logistics',
		children: [
			{
				title: '',
				name: '设备材料注册',
				to: '/logistics/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '设备材料审批',
				to: '/logistics/2',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: '设备材料进场',
				to: '/logistics/2',
				children: [],
				is_show: false,
				order: 3
			}
		],
		is_show: false,
		order: 9
	},
	{
		title: 'fire',
		name: '能源管理(二期)',
		to: '/energy',
		children: [
			{
				title: '',
				name: '用电管理',
				to: '/energy/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '用水管理',
				to: '/energy/2',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: '报警条件设置',
				to: '/energy/3',
				children: [],
				is_show: false,
				order: 3
			}
		],
		is_show: false,
		order: 10
	},
	{
		title: 'file',
		name: '图纸管理(二期)',
		to: '/papers',
		children: [
			{
				title: '',
				name: '我的图纸',
				to: '/papers/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '所有图纸',
				to: '/papers/2',
				children: [],
				is_show: false,
				order: 2
			}
		],
		is_show: false,
		order: 11
	},
	{
		title: 'slack',
		name: 'SuperOpl(二期)',
		to: '/super',
		children: [
			{
				title: '',
				name: 'escalation规则管理',
				to: '/super/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '我的OPL',
				to: '/super/2',
				children: [],
				is_show: false,
				order: 2
			},
			{
				title: '',
				name: '所有OPL',
				to: '/super/3',
				children: [],
				is_show: false,
				order: 3
			}
		],
		is_show: false,
		order: 12
	},
	{
		title: 'huiyiguanli',
		name: <FormattedMessage id='menu.meeting' defaultMessage='会议管理' />,
		to: '/meeting',
		children: [
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.meeting.type'
						defaultMessage='会议类型'
					/>
				),
				to: '/meeting/type',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.meeting.minutes'
						defaultMessage='会议纪要'
					/>
				),
				to: '/meeting/minutes',
				children: [],
				is_show: false,
				order: 2
			}
			// { "title": "", "name": "会议室预订", "to": "/meeting/2", "children": [], "is_show": false, "order": 3 }
		],
		is_show: false,
		order: 13
	},
	{
		title: 'snippets',
		name: '知识管理(二期)',
		to: '/knowledge',
		children: [
			{
				title: '',
				name: '子模块一',
				to: '/knowledge/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '子模块二',
				to: '/knowledge/2',
				children: [],
				is_show: false,
				order: 2
			}
		],
		is_show: false,
		order: 14
	},
	{
		title: 'youtube',
		name: '安全监控(二期)',
		to: '/monitoring',
		children: [
			{
				title: '',
				name: '子模块一',
				to: '/monitoring/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '子模块二',
				to: '/monitoring/2',
				children: [],
				is_show: false,
				order: 2
			}
		],
		is_show: false,
		order: 15
	},
	{
		title: 'bank',
		name: '商务管理(二期)',
		to: '/business',
		children: [
			{
				title: '',
				name: '子模块一',
				to: '/business/1',
				children: [],
				is_show: false,
				order: 1
			},
			{
				title: '',
				name: '子模块二',
				to: '/business/2',
				children: [],
				is_show: false,
				order: 2
			}
		],
		is_show: false,
		order: 16
	},
	{
		title: 'heat-map',
		name: '施工管理',
		to: '/construction',
		children: [
			{
				title: '',
				name: '职务管理',
				to: '/construction/worktype',
				children: [],
				is_show: false,
				order: 1,
				wx_url: null
			},
			{
				title: '',
				name: '组织管理',
				to: '/construction/contractor',
				children: [],
				is_show: false,
				order: 2,
				wx_url: null
			},
			{
				title: '',
				name: '工人管理',
				to: '/construction/worker',
				children: [],
				is_show: false,
				order: 3,
				wx_url: null
			},
			{
				title: '',
				name: '工人审批',
				to: '/construction/worker/pending',
				children: [],
				is_show: false,
				order: 4,
				wx_url: null
			},
			{
				title: '',
				name: '工人审批记录',
				to: '/construction/worker/do',
				children: [],
				is_show: false,
				order: 5,
				wx_url: null
			},
			{
				title: '',
				name: '施工预约',
				to: '/construction/order',
				children: [],
				is_show: false,
				order: 6,
				wx_url: null
			},
			{
				title: '',
				name: '施工审批',
				to: '/construction/order/pending',
				children: [],
				is_show: false,
				order: 7
			},
			{
				title: '',
				name: '施工审批记录',
				to: '/construction/order/do',
				children: [],
				is_show: false,
				order: 8,
				wx_url: null
			},
			{
				title: '',
				name: '施工浏览',
				to: '/construction/viewproject',
				children: [],
				is_show: false,
				order: 9,
				wx_url: null
			},
			{
				title: '',
				name: '施工人员监控',
				to: '/construction/viewlistener',
				children: [],
				is_show: false,
				order: 10,
				wx_url: null
			}
		],
		is_show: false,
		order: 99,
		wx_url: null
	},
	{
		title: 'gongzuoliu',
		name: <FormattedMessage id='menu.workflow.old' defaultMessage='工作流管理(旧)' />,
		to: '/workflow',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.workflow.form.title'
						defaultMessage='配置各类工作流所需使用的线上表单标准格式'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.form'
						defaultMessage='表单配置'
					/>
				),
				to: '/workflow/template',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.approval.title'
						defaultMessage='创建各组织（单位）的审批流模版'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.approval'
						defaultMessage='审批流配置'
					/>
				),
				to: '/approval/flow/template',
				children: [],
				is_show: true,
				order: 2
			},
			// { "title": "", "name": "分类配置", "to": "/workflow/classification", "children": [], "is_show": false, "order": 3 },
			{
				title: (
					<FormattedMessage
						id='menu.workflow.type.title'
						defaultMessage='关联工作流表单和审批流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.type'
						defaultMessage='工作流类型'
					/>
				),
				to: '/workflow/flow',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.all workflows.title'
						defaultMessage='查看所有工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.all workflows'
						defaultMessage='所有工作流'
					/>
				),
				to: '/workflow/allrecord',
				children: [],
				is_show: true,
				order: 5
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.my workflows.title'
						defaultMessage='查看与自己有关的工作流进展，并可选择发起新的工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.my workflows'
						defaultMessage='我的工作流'
					/>
				),
				to: '/workflow/record',
				children: [],
				is_show: true,
				order: 6
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.pending.title'
						defaultMessage='审批等待自己处理的工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.pending'
						defaultMessage='待处理工作流'
					/>
				),
				to: '/workflow/record/wait',
				children: [],
				is_show: true,
				order: 7
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.workflow.document.title'
						defaultMessage='浏览工作流审批所产生的相关文档'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.workflow.document'
						defaultMessage='工作流文档'
					/>
				),
				to: '/workflow/document',
				children: [],
				is_show: true,
				order: 8
			}
			// { "title": "", "name": "所有工作流记录", "to": "/workflow/allrecord", "children": [], "is_show": true, "order": 7 },
			// { "title": "", "name": "工作流文档", "to": "/workflow/source", "children": [], "is_show": true, "order": 8 },
		],
		is_show: true,
		order: 17
	},
	{
		title: 'renwuguanli',
		name: <FormattedMessage id='menu.task' defaultMessage='任务管理' />,
		to: '/assignment',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.task.area.title'
						defaultMessage='配置任务的区域划分'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.task.area'
						defaultMessage='区域管理'
					/>
				),
				to: '/assignment/area',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.task.type'
						defaultMessage='任务类型的维护'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.task.type'
						defaultMessage='任务类型'
					/>
				),
				to: '/assignment/type',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: (
					<FormattedMessage
						id='menu.task.rules'
						defaultMessage='执行规则的配置'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.task.rules'
						defaultMessage='执行规则'
					/>
				),
				to: '/assignment/rule',
				children: [],
				is_show: false,
				order: 3
			},
			{
				title: (
					<FormattedMessage
						id='menu.task.mytask'
						defaultMessage='查看与自己有关的任务，并可选择发起新的任务'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.task.my task'
						defaultMessage='我的任务'
					/>
				),
				to: '/assignment/record',
				children: [],
				is_show: true,
				order: 4
			},
			// { "title": "", "name": "任务浏览", "to": "/assignment/all", "children": [], "is_show": true, "order": 5 },
			{
				title: (
					<FormattedMessage
						id='menu.task.pending'
						defaultMessage='查看等待自己处理的任务'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.task.pending'
						defaultMessage='待处理任务'
					/>
				),
				to: '/assignment/wait',
				children: [],
				is_show: true,
				order: 6
			},
			{
				title: '',
				name: (
					<FormattedMessage
						id='menu.task.chart'
						defaultMessage='任务图表'
					/>
				),
				to: '/assignment/dashboard',
				children: [],
				is_show: true,
				order: 7
			}
			// { "title": "", "name": "所有工作流记录", "to": "/workflow/allrecord", "children": [], "is_show": true, "order": 7 },
			// { "title": "", "name": "工作流文档", "to": "/workflow/source", "children": [], "is_show": true, "order": 8 },
		],
		is_show: true,
		order: 18
	},
	{
		title: 'gongzuoliu',
		name: <FormattedMessage id='menu.workflow' defaultMessage='工作流管理' />,
		to: '/workflow2',
		children: [
			{
				title: (
					<FormattedMessage
						id='menu.workflow2.type.title'
						defaultMessage='配置工作流类型和审批流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow2.type'
						defaultMessage='工作流配置'
					/>
				),
				to: '/workflow2/flow',
				children: [],
				is_show: true,
				order: 1
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.all workflows.title'
						defaultMessage='查看所有工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.all workflows'
						defaultMessage='所有工作流'
					/>
				),
				to: '/workflow2/allrecord',
				children: [],
				is_show: true,
				order: 2
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.my workflows.title'
						defaultMessage='查看与自己有关的工作流进展，并可选择发起新的工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.my workflows'
						defaultMessage='我的工作流'
					/>
				),
				to: '/workflow2/record',
				children: [],
				is_show: true,
				order: 3
			},
			{
				title: (
					<FormattedMessage
						id='menu.workflow.pending.title'
						defaultMessage='审批等待自己处理的工作流'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.workflow.pending'
						defaultMessage='待处理工作流'
					/>
				),
				to: '/workflow2/wait',
				children: [],
				is_show: true,
				order: 4
			},
			{
				title: (
					<FormattedMessage
						id='menu.document.workflow.document.title'
						defaultMessage='浏览工作流审批所产生的相关文档'
					/>
				),
				name: (
					<FormattedMessage
						id='menu.document.workflow.document'
						defaultMessage='工作流文档'
					/>
				),
				to: '/workflow2/document',
				children: [],
				is_show: true,
				order: 5
			}
		],
		is_show: true,
		order: 19
	},
	// CAD图纸管理
	{
		title: 'CADtuzhi',
		name: 'CAD图纸管理',
		is_show: true,
		order: 20,
		to: '/blueprint',
		children: [
			{
				title: '',
				name: '图纸目录',
				to: '/blueprint/directory',
				permissionList: ['/blueprint/directory'],
				children: [],
				order: 1
			},
			{
				title: '',
				name: '图纸管理',
				to: '/blueprint/document',
				permissionList: ['/blueprint/document'],
				children: [],
				order: 2
			},
			{
				title: '',
				name: '所有图纸',
				to: '/blueprint/alldocument',
				permissionList: ['/blueprint/alldocument'],
				children: [],
				order: 3
			},
			{
				title: '',
				name: '查看评论',
				to: '/blueprint/comment',
				permissionList: ['/blueprint/comment'],
				children: [],
				order: 4
			}
		]
	}
];

export default menuList;
