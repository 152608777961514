export const csvInjection = (workSheet = {}) => {
  return Object.fromEntries(
    Object.entries(workSheet).map(([pos, cell]) => {
      if (
        cell.t === "s" &&
        ["+", "-", "@", "="].some((ch) => cell.v.startsWith(ch))
      ) {
        return [
          pos,
          {
            ...cell,
            v: "'" + cell.v,
          },
        ];
      }
      return [pos, cell];
    })
  );
};
