import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import s from './MessageTab.css';
import cn from 'classnames';
import { Tabs, Tooltip, Empty, Tag, message } from 'antd';
import { List as VList } from 'react-virtualized';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import CommonUtil from '@utils/common';
import { fetchProjectsInfo, fetchNoticeInfo, fetchTodoInfo, noticeRead, noticeDelete, todoDelete } from './service';

message.config({ maxCount: 1 });
const { TabPane } = Tabs;
const _util = new CommonUtil();

@withRouter
@injectIntl
class MessageTab extends Component {
	state = {
		loading: false,
		projects: [],
		notice: [],
		notice_count: 0,
		todo: [],
		todo_count: 0,
		currentId: ''
	};

	componentDidMount = () => {
		this.fetchProjects();
	};

	fetchProjects = async currentId => {
		const res = await fetchProjectsInfo();
		if (res && res.data && res.data.length !== 0) {
			this.setState({
				projects: res.data,
				notice_count: res.data[0].notice_count,
				todo_count: res.data[0].notice_count,
				currentId: currentId || res.data[0].project_id
			});
			this.fetchNotice(currentId || res.data[0].project_id);
			this.fetchTodo(currentId || res.data[0].project_id);
		}
	};

	fetchNotice = async projectId => {
		const res = await fetchNoticeInfo(projectId);
		if (res && res.data && res.data.length !== 0) this.setState({ notice: res.data.results });
	};

	fetchTodo = async projectId => {
		const res = await fetchTodoInfo(projectId);
		if (res && res.data && res.data.length !== 0) this.setState({ todo: res.data.results });
	};

	handleSwitchProject = projectId => {
		this.fetchNotice(projectId);
		this.fetchTodo(projectId);
		this.setState({ currentId: projectId });
	};

	handleReadNotice = id => {
		const { currentId } = this.state;
		noticeRead(currentId, { id }).then(res => {
			if (res) {
				this.fetchNotice(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	handleReadNoticeAll = () => {
		const { currentId } = this.state;
		noticeRead(currentId, { mode: true }).then(res => {
			if (res) {
				this.fetchNotice(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	handleDelNotice = id => {
		const { currentId } = this.state;
		noticeDelete(currentId, { id }).then(res => {
			if (res) {
				this.fetchNotice(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	handleDelNoticeAll = () => {
		const { currentId } = this.state;
		noticeDelete(currentId, { mode: true }).then(res => {
			if (res) {
				this.fetchNotice(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	renderNotice = notice => {
		return (
			<VList
				width={320}
				rowCount={notice.length}
				rowHeight={92}
				height={
					notice.length === 1
						? 92
						: notice.length === 2
						? 184
						: notice.length === 3
						? 276
						: notice.length === 4
						? 300
						: 370
				}
				rowRenderer={({ index, key, style }) => {
					const item = notice[index];
					const ob = {};
					if (item.extra_info) {
						const _ob = JSON.parse(item.extra_info);
						ob['id'] = _ob.id;
						ob['directory_id'] = _ob.directory_id;
					}
					return (
						<div
							className={s.noticeContent}
							key={key}
							style={{
								...style,
								overflow: 'hidden',
								padding: 10,
								borderTop: index === 0 ? 0 : '1px solid #e8e8e8',
								display: 'flex'
							}}
						>
							<div className={s.noticeItemLeft}>
								<img
									src={require('./dd.png')}
									style={{
										display: 'block',
										width: 30,
										height: 30,
										borderRadius: '100%',
										margin: '2px auto 0'
									}}
								/>
								{!item.is_read && (
									<div
										style={{
											textAlign: 'center',
											fontSize: 12,
											height: 20,
											lineHeight: '20px',
											cursor: 'pointer'
										}}
										onClick={() => this.handleReadNotice(item.id)}
									>
										<FormattedMessage id='global.read' defaultMessage='已读' />
									</div>
								)}
								<div
									style={{
										textAlign: 'center',
										fontSize: 12,
										height: 20,
										lineHeight: '20px',
										cursor: 'pointer'
									}}
									onClick={() => this.handleDelNotice(item.id)}
								>
									<FormattedMessage
										id='page.inspection.Delete'
										defaultMessage='删除'
									/>
								</div>
							</div>

							<div className={s.noticeItemRight}>
								<div
									className={s.noticeItemTitle}
									style={{
										cursor: ob.id ? 'pointer' : ''
									}}
									onClick={() => {
										if (ob.id) {
											this.props.history.push({
												pathname: '/document/register/document/detail',
												state: {
													id: ob.id,
													directory: ob.directory_id,
													password: ''
												}
											});
										}
									}}
								>
									{item.title}
								</div>
								<Tooltip
									title={<span>{item.content && item.content}</span>}
									placement='bottom'
								>
									<div className={s.itemContent}>{item.content && item.content}</div>
								</Tooltip>
								<div className={s.itemContent}>{moment(item.created_time).fromNow()}</div>
							</div>
						</div>
					);
				}}
			/>
		);
	};

	handleDelTodo = id => {
		const { currentId } = this.state;
		todoDelete(currentId, { id }).then(res => {
			if (res) {
				this.fetchTodo(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	handleDelTodoAll = () => {
		const { currentId } = this.state;
		todoDelete(currentId, { mode: true }).then(res => {
			if (res) {
				this.fetchTodo(currentId);
				this.fetchProjects(currentId);
			}
		});
	};

	handleClickTodo = todoItem => {
		const { t_type, d_id, extra_param } = todoItem;
		const { currentId } = this.state;
		const { currentProjectId, currentProjectChange } = this.props;
		if (currentProjectId !== currentId) currentProjectChange(currentId);
		switch (t_type) {
			case 1:
				this.props.history.push('/');
				break;
			case 2:
				this.props.history.push({
					pathname: '/workflow/record/wait/pdf',
					state: {
						idx: d_id,
						type: 2
					}
				});
				break;
			case 3:
				this.props.history.push(`/system/org/application/audit/${d_id}`);
				break;
			case 4:
				this.props.history.push({
					pathname: '/system/staff/approve/audit',
					state: {
						id: d_id
					}
				});
				break;
			case 5:
				this.props.history.push({
					pathname: '/safety/my/factoryapply/audit',
					state: {
						id: d_id
					}
				});
				break;
			case 6:
				message.warning('请在手机上查看');
				break;
			case 7:
				this.props.history.push({
					pathname: '/assignment/wait/detail',
					state: {
						id: d_id
					}
				});
				break;
			case 8:
				this.props.history.push({
					pathname: '/complete/document'
				});
				break;
			case 9:
				this.props.history.push({
					pathname: `/workflow2/wait/detail/${d_id}`,
					state: {
						id: d_id
					}
				});
				break;
			case 10:
				this.props.history.push({
					pathname: '/document/register/document/detail',
					state: {
						id: d_id,
						directory: JSON.parse(extra_param)?.directory_id
					}
				});
				break;

			default:
				this.props.history.push('/');
				break;
		}
	};

	renderTodo = todo => {
		return (
			<VList
				width={320}
				rowCount={todo.length}
				rowHeight={92}
				height={
					todo.length === 1
						? 92
						: todo.length === 2
						? 184
						: todo.length === 3
						? 276
						: todo.length === 4
						? 300
						: 370
				}
				rowRenderer={({ index, key, style }) => {
					const item = todo[index];
					return (
						<div
							className={s.todoContent}
							key={key}
							style={{
								...style,
								overflow: 'hidden',
								padding: 10,
								borderTop: index === 0 ? 0 : '1px solid #e8e8e8',
								display: 'flex'
							}}
						>
							<div className={s.todoItemLeft}>
								<div className={s.todoItemTitle} onClick={() => this.handleClickTodo(item)}>
									{item.title}
								</div>
								<div>
									<Tooltip
										title={<span>{item.content ? item.content : ''}</span>}
										placement='bottom'
									>
										<div className={s.todoItemContent}>
											{item.content && item.content}
										</div>
									</Tooltip>
									<div>
										{item.created_time && _util.getTimeNoTip(item.created_time)}
									</div>
								</div>
							</div>

							<div className={s.todoItemRight}>
								<Tag
									color='red'
									className={s.todoDelBtn}
									onClick={() => this.handleDelTodo(item.id)}
								>
									<FormattedMessage
										id='page.inspection.Delete'
										defaultMessage='删除'
									/>
								</Tag>
							</div>
						</div>
					);
				}}
			/>
		);
	};

	render = () => {
		const { projects, notice, todo, currentId } = this.state;
		const projects_ln = projects.length;
		const { formatMessage } = this.props.intl;
		const noReadNotice = notice.filter(n => !n.is_read);
		const noReadNoticeLn = noReadNotice.length;
		const todo_ln = todo.length;
		const { fetchCount } = this.props;
		if (fetchCount) {
			fetchCount(todo_ln + noReadNoticeLn);
		}
		console.log(todo_ln + noReadNoticeLn);

		return (
			<div className={s.root}>
				<div className={s.left}>
					<div className={s.leftHeader}>
						{projects_ln !== 0 ? (
							<FormattedMessage id='page.testcar.bind.name' defaultMessage='项目名称' />
						) : (
							<FormattedMessage id='page.testcar.bind.name' defaultMessage='项目名称' />
						)}
					</div>
					<div className={s.leftContent}>
						{projects_ln !== 0 &&
							projects.map(p => (
								<div key={p.project_id} className={s.projectItem}>
									<Tooltip title={`${p.project_name}`}>
										<div
											onClick={() =>
												this.handleSwitchProject(p.project_id)
											}
											className={
												p.project_id === currentId
													? cn(s.title, s.checked)
													: s.title
											}
										>
											{`${p.project_name} `}
										</div>
									</Tooltip>
									<div>{p.notice_count + p.todo_count}</div>
								</div>
							))}
					</div>
				</div>

				<div className={s.right}>
					<Tabs defaultActiveKey='a'>
						<TabPane
							key='a'
							tab={
								noReadNoticeLn !== 0
									? formatMessage({
											id: 'global.message',
											defaultMessage: '消息'
									  }) + `(${noReadNoticeLn})`
									: formatMessage({
											id: 'global.message',
											defaultMessage: '消息'
									  })
							}
						>
							{noReadNoticeLn !== 0 && (
								<div className={s.rightItem}>
									{this.renderNotice(noReadNotice)}
									<div className={s.handleBtnGroup}>
										<div
											className={s.readBtn}
											onClick={this.handleReadNoticeAll}
										>
											<FormattedMessage
												id='global.clear-message'
												defaultMessage='全部已读'
											/>
										</div>
										<div className={s.delBtn} onClick={this.handleDelNoticeAll}>
											<FormattedMessage
												id='global.clear-all'
												defaultMessage='全部删除'
											/>
										</div>
									</div>
								</div>
							)}
							{noReadNoticeLn === 0 && (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={s.empty} />
							)}
						</TabPane>
						<TabPane
							key='b'
							tab={
								todo_ln !== 0
									? formatMessage({
											id: 'global.todo',
											defaultMessage: '待办'
									  }) + `(${todo_ln})`
									: formatMessage({
											id: 'global.todo',
											defaultMessage: '待办'
									  })
							}
						>
							{todo_ln !== 0 && (
								<div className={s.rightItem}>
									{this.renderTodo(todo)}
									<div className={s.delTodoBtn} onClick={this.handleDelTodoAll}>
										<FormattedMessage
											id='global.clear-all'
											defaultMessage='全部删除'
										/>
									</div>
								</div>
							)}
							{todo_ln === 0 && (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={s.empty} />
							)}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	};
}

export default MessageTab;
