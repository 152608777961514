import axios from "axios";
import CommonUtil from "@utils/common";

const _util = new CommonUtil();

const _url = "/super/statusbar/param/";

const Notice = params => { 
    return axios.get(_util.getServerUrl(_url), { params: params }); 
};

const NoticePost = params => {
    return axios.post(_util.getServerUrl(_url), params); 
};

const NoticeGet = (project_id,params) => {
    return axios.get(_util.getServerUrl(`/system/getstatusbar/?project_id=${project_id}`), { params: params });
}

const NoticeDetail = (params) => {
    return axios.get(_util.getServerUrl(`${_url}${params}/`), params); 
};

const NoticePut = (params) => {
    console.log("Notice",params)
    return axios.put(_util.getServerUrl(`${_url}${params.id}/`), params)
}
const NoticeDelete = (id, params) => { return axios.delete(_util.getServerUrl(`${_url}${id}/`), params) }

export {
    Notice,
    NoticePost,
    NoticePut,
    NoticeGet,
    NoticeDetail,
    NoticeDelete
};
