import React, { Component } from "react";
import { Drawer } from "antd";
import s from "./style/index.css";
import { currentVerInfo } from "./service";
import { Timeline } from "antd";
import BraftEditor from "braft-editor";
import moment from "moment";

export default class extends Component {
  state = {
    versionInfo: [],
    showValue: "",
    vv: "",
  };

  componentDidMount = async () => {
    const res = await currentVerInfo();
    this.setState({
      versionInfo: res.data,
      showValue: BraftEditor.createEditorState(res.data[0].content),
      vv: res.data[0].version,
    });
    if (res.data[0]?.version) {
      localStorage.setItem("v", res.data[0].version);
    }
  };

  back = () => {
    const { close } = this.props;
    if (typeof close === "function") close();
  };

  render() {
    const { versionInfo, showValue, vv } = this.state;
    return (
      <div>
        <Drawer
          placement="left"
          width={"100vw"}
          destroyOnClose
          closable={false}
          maskClosable={false}
          mask={false}
          keyboard={false}
          visible
        >
          <div className={s.root}>
            <div className={s.left}>
              <div className={s.backBtn} onClick={this.back}></div>
            </div>
            <div className={s.right}>
              <div style={{ overflow: "hidden" }}>
                <div className={s.timeline}>
                  <Timeline>
                    {versionInfo.length &&
                      versionInfo.map((v) => (
                        <>
                          <Timeline.Item color="#4169E1">
                            <div
                              style={{
                                color: "#4169E1",
                                cursor: "pointer",
                                background: v.version === vv ? "yellow" : "",
                              }}
                              onClick={() =>
                                this.setState({
                                  vv: v.version,
                                  showValue: BraftEditor.createEditorState(
                                    v.content
                                  ),
                                })
                              }
                            >
                              版本:
                              {v.version}
                            </div>
                          </Timeline.Item>

                          <Timeline.Item color="#8bb4b4">
                            创建时间:{" "}
                            {moment(v.created_time).format("YYYY-MM-DD")}
                            <p></p>
                            <br />
                          </Timeline.Item>
                        </>
                      ))}
                    <Timeline.Item color="#8bb4b4">END</Timeline.Item>
                  </Timeline>
                </div>
              </div>

              <div className={s.info}>
                <div
                  style={{
                    color: "#4169E1",
                  }}
                >
                  {vv}版本详细信息 :
                </div>
                <div style={{ maxWidth: "800px" }}>
                  <BraftEditor readOnly controls={[]} value={showValue} />
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
