import axios from 'axios';
import CommonUtil from '@utils/common';
const _util = new CommonUtil();

const fetchProjectsInfo = () => {
	return axios.get(_util.getServerUrl('/system/total/count/'));
};

const fetchNoticeInfo = projectId => {
	return axios.get(_util.getServerUrl(`/system/notice/?project_id=${projectId}`), {
		params: { page_size: 9999999999999, page: 1 }
	});
};

const fetchTodoInfo = projectId => {
	return axios.get(_util.getServerUrl(`/system/todo/?project_id=${projectId}`), {
		params: { page_size: 9999999999999, page: 1 }
	});
};

const noticeRead = (id, params) => {
	return axios.post(_util.getServerUrl(`/system/notice/read/?project_id=${id}`), params);
};

const noticeDelete = (id, params) => {
	return axios.post(_util.getServerUrl(`/system/notice/delete/?project_id=${id}`), params);
};

const todoDelete = (id, params) => {
	return axios.post(_util.getServerUrl(`/system/todo/delete/?project_id=${id}`), params);
};

export { fetchProjectsInfo, fetchNoticeInfo, fetchTodoInfo, noticeRead, noticeDelete, todoDelete };
