/** @format */

import React from 'react';
import { observable, action, computed } from 'mobx';

class TableStore {
	@observable scrollTopState = new Map();
	@observable currentState = new Map();
	@observable pageSizeState = new Map();
	@observable totalState = new Map();
	@observable searchState = new Map();
	@observable paramsState = new Map();

	@action clearAll() {
		this.scrollTopState.clear();
		this.currentState.clear();
		this.pageSizeState.clear();
		this.totalState.clear();
		this.searchState.clear();
		this.paramsState.clear();
	}

	@action setScrollTop(newScrollTop, path) {
		const pathname = path || window.location.pathname;

		return this.scrollTopState.set(pathname, newScrollTop);
	}

	@action clearScrollTop(path) {
		const pathname = path || window.location.pathname;

		return this.scrollTopState.delete(pathname);
	}

	@action setCurrent(newCurrent, path) {
		const pathname = path || window.location.pathname;

		return this.currentState.set(pathname, newCurrent);
	}

	@action clearCurrent(path) {
		const pathname = path || window.location.pathname;

		return this.currentState.delete(pathname);
	}

	@action setPageSize(newPageSize, path) {
		const pathname = path || window.location.pathname;

		return this.pageSizeState.set(pathname, newPageSize);
	}

	@action setTotal(newTotal, path) {
		const pathname = path || window.location.pathname;

		return this.totalState.set(pathname, newTotal);
	}

	@action setSearch(newSearch, path) {
		const pathname = path || window.location.pathname;

		return this.searchState.set(pathname, newSearch);
	}

	@action setParams(newParams, path) {
		const mergedParams = {};

		if (newParams && Object.keys(newParams).length > 0) {
			for (const key of Object.keys(newParams)) {
				if (Array.isArray(newParams[key])) {
					mergedParams[key] = newParams[key].join(',');
				} else {
					mergedParams[key] = newParams[key];
				}
			}
		}
		const pathname = path || window.location.pathname;

		if (this.paramsState.has(pathname)) {
			const oldParams = this.paramsState.get(pathname);

			return this.paramsState?.set(pathname, {
				...oldParams,
				...mergedParams,
			});
		}

		return this.paramsState.set(pathname, mergedParams);
	}

	@action clearParams(path) {
		const pathname = path || window.location.pathname;
		return this.paramsState.delete(pathname);
	}

	@computed get scrollTop() {
		const pathname = window.location.pathname;

		return this.scrollTopState.get(pathname);
	}

	@action getScrollTop(path) {
		const pathname = path || window.location.pathname;

		return this.scrollTopState.get(pathname);
	}

	@computed get current() {
		const pathname = window.location.pathname;

		return this.currentState.get(pathname);
	}

	@action getCurrent(path) {
		const pathname = path || window.location.pathname;

		return this.currentState.get(pathname);
	}

	@computed get pageSize() {
		const pathname = window.location.pathname;

		return this.pageSizeState.get(pathname);
	}

	@action getPageSize(path) {
		const pathname = path || window.location.pathname;

		return this.pageSizeState.get(pathname);
	}

	@computed get total() {
		const pathname = window.location.pathname;

		return this.totalState.get(pathname);
	}

	@action getTotal(path) {
		const pathname = path || window.location.pathname;

		return this.totalState.get(pathname);
	}

	@computed get search() {
		const pathname = window.location.pathname;

		return this.searchState.get(pathname);
	}

	@action getSearch(path) {
		const pathname = path || window.location.pathname;

		return this.searchState.get(pathname);
	}

	@computed get params() {
		const pathname = window.location.pathname;

		return this.paramsState.get(pathname);
	}

	@action getParams(path) {
		const pathname = path || window.location.pathname;

		return this.paramsState.get(pathname);
	}

	@computed get pagination() {
		const pathname = window.location.pathname;

		return {
			current: this.currentState.get(pathname) || 1,
			pageSize: this.pageSizeState.get(pathname) || 200,
			total: this.totalState.get(pathname) ?? 0,
		};
	}

	@action getPagination(path) {
		const pathname = path || window.location.pathname;

		return {
			current: this.currentState.get(pathname) || 1,
			pageSize: this.pageSizeState.get(pathname) || 200,
			total: this.totalState.get(pathname) ?? 0,
		};
	}
}

const tableStore = new TableStore();

export default tableStore;
